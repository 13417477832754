export const images = [
  {
    src: "/images/latest/1.jpeg",
    original: "/images/latest/1.jpeg",
    width: 720,
    height: 574,
  },
  {
    src: "/images/latest/2.jpeg",
    original: "/images/latest/2.jpeg",
    width: 320,
    height: 212,
  },
  {
    src: "/images/latest/3.jpeg",
    original: "/images/latest/3.jpeg",
    width: 320,
    height: 212,
  },
  {
    src: "/images/latest/4.jpeg",
    original: "/images/latest/4.jpeg",
    width: 320,
    height: 213,
  },
  {
    src: "/images/latest/5.jpeg",
    original: "/images/latest/5.jpeg",
    width: 320,
    height: 183,
  },
  {
    src: "/images/latest/6.jpeg",
    original: "/images/latest/6.jpeg",
    width: 240,
    height: 320,
  },
  {
    src: "/images/latest/7.jpeg",
    original: "/images/latest/7.jpeg",
    width: 320,
    height: 190,
  },
  {
    src: "/images/latest/8.jpeg",
    original: "/images/latest/8.jpeg",
    width: 320,
    height: 148,
  },
  {
    src: "/images/latest/9.jpeg",
    original: "/images/latest/9.jpeg",
    width: 320,
    height: 213,
  },
  {
    src: "/images/latest/10.jpeg",
    original: "/images/latest/10.jpeg",
    width: 320,
    height: 213,
  },
  {
    src: "/images/latest/11.jpeg",
    original: "/images/latest/11.jpeg",
    width: 320,
    height: 179,
  },
  {
    src: "/images/latest/12.jpeg",
    original: "/images/latest/12.jpeg",
    width: 320,
    height: 215,
  },
  {
    src: "/images/latest/13.jpeg",
    original: "/images/latest/13.jpeg",
    width: 257,
    height: 320,
  },
  {
    src: "/images/latest/14.jpeg",
    original: "/images/latest/14.jpeg",
    width: 226,
    height: 320,
  },
  {
    src: "/images/latest/15.jpeg",
    original: "/images/latest/15.jpeg",
    width: 320,
    height: 179,
  },
  {
    src: "/images/latest/16.jpeg",
    original: "/images/latest/16.jpeg",
    width: 320,
    height: 215,
  },
  {
    src: "/images/latest/17.jpeg",
    original: "/images/latest/17.jpeg",
    width: 257,
    height: 320,
  },
  {
    src: "/images/latest/18.jpeg",
    original: "/images/latest/18.jpeg",
    width: 226,
    height: 320,
  },
  {
    src: "/images/latest/19.jpeg",
    original: "/images/latest/19.jpeg",
    width: 320,
    height: 213,
  },
];